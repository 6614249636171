const styles = theme => ({
  pageContainer: {
    height: '100%',
    backgroundColor: theme.palette.primary.main
  },
  title: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputContainer: {
    width: 507,
    backgroundColor: theme.palette.primary.dark,
    padding: 12,
    paddingLeft: 18
  },
  footer: {
    width: 507,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  inputFeedback: {
    fontStyle: 'italic',
    color: theme.palette.primary.light,
    marginTop: 5
  },
  stepCounter: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: '#FFFFFF',
    width: 40
  },
  rowAlignCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginLeft: 7,
    marginBottom: 3,
    fontSize: 22,
    height: 22,
    color: theme.palette.primary.light
  }
});

export default styles;
