import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './styles';

const MagicLinkView = ({ redirect, classes }) => {
  return (
    <div
      style={{
        marginTop: 20
      }}
    >
      <Typography style={{ fontFamily: 'Aller Display', fontSize: 20 }}>
        HOORAY, YOU'VE CREATED A NEW EVENT!
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={() => redirect()}
      >
        <Typography
          style={{
            fontFamily: 'Aller Display',
            fontSize: 20,
            color: '#DB80FF'
          }}
        >
          LOGIN TO SET IT UP
        </Typography>
        <FontAwesomeIcon className={classes.icon} icon={faSignInAlt} />
      </div>
    </div>
  );
};

export default withStyles(styles)(MagicLinkView);
