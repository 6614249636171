const styles = theme => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row'
  },
  radioColumn: {
    paddingRight: 10
  },
  iconButton: {
    color: theme.palette.primary.light,
    alignSelf: 'flex-start'
  },
  icon: {
    fontSize: 22,
    height: 22,
    color: '#FFFFFF'
  }
})

export default styles
