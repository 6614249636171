import axios from 'axios';

export const _checkEventTitle = keyword => {
  const formData = new FormData();
  formData.append('keyword', keyword);
  return axios.post(
    'https://staging.bidr.co/api/events/keywordcheck?key=ce52728f580499be6d00e2acbe8b996f',
    formData
  );
};

export const _checkPhoneNumber = phoneNumber => {
  return axios.get(
    `https://staging.bidr.co/api/users/phone/+1${phoneNumber}?key=ce52728f580499be6d00e2acbe8b996f`
  );
};

export const _checkEmail = email => {
  return axios.get(
    `https://staging.bidr.co/api/users/email/${email}?key=ce52728f580499be6d00e2acbe8b996f`
  );
};

export const _createNewUser = (email, name, phone, password) => {
  const formData = new FormData();
  formData.append('user_email', email);
  formData.append('user_name', name);
  formData.append('user_phone', phone);
  formData.append('user_pass', password);
  return axios.post(
    'https://staging.bidr.co/api/users/create?key=ce52728f580499be6d00e2acbe8b996f',
    formData
  );
};

export const _createNewEvent = (userId, eventTitle) => {
  const formData = new FormData();
  formData.append('user_id', userId);
  formData.append('type', 1);
  formData.append('keyword', eventTitle);
  return axios.post(
    'https://staging.bidr.co/api/events/create?key=ce52728f580499be6d00e2acbe8b996f',
    formData
  );
};

export const _updateNewEvent = (
  eid,
  keyword,
  checkboxArray,
  estimatedFunding
) => {
  console.log('estimatedFunding:', estimatedFunding);
  const formData = new FormData();
  formData.append('eids[]', eid);
  formData.append(
    'attributes',
    JSON.stringify({
      auction_title: keyword,
      feature_intent: `${checkboxArray}`,
      intended_raise: estimatedFunding
    })
  );
  return axios.post(
    'https://staging.bidr.co/api/events/update?key=ce52728f580499be6d00e2acbe8b996f',
    formData
  );
};

export const _generateMagicLink = (userUrl, userUrlHash, eid) => {
  return new Promise((resolve, reject) => {
    if ((userUrl, userUrlHash, eid)) {
      resolve(
        `https://manager.event.gives/start/${userUrl}-${userUrlHash}/events/${eid}`
      );
    } else {
      reject('missing magic link param');
    }
  });
};

export const _authByPass = (phone, password) => {
  const phoneNumber = phone.replace(/[- )(]/g, '');
  const formData = new FormData();
  formData.append('user_phone', `+1${phoneNumber}`);
  formData.append('user_pass', password);
  return axios.post(
    'https://staging.bidr.co/api/auth?key=ce52728f580499be6d00e2acbe8b996f',
    formData
  );
};

export const _authByPassEmail = (email, password) => {
  const formData = new FormData();
  formData.append('user_email', email);
  formData.append('user_pass', password);
  return axios.post(
    'https://staging.bidr.co/api/auth?key=ce52728f580499be6d00e2acbe8b996f',
    formData
  );
};
