import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import styles from './styles'

function LinearDeterminate({ classes, step }) {
  const progress = step => {
    return ((step - 1) / 7) * 100
  }

  return (
    <div className={classes.root}>
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={progress(step)}
      />
    </div>
  )
}

export default withStyles(styles)(LinearDeterminate)
