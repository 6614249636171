import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './store/reducers/rootReducer'
import DemoFrame from './components/DemoFrame'
import App from './components/App'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './themes/main'
import './index.css'

const createStoreWithMiddleware = compose(applyMiddleware(thunk)(createStore))

const store = createStoreWithMiddleware(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

class Application extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <DemoFrame>
          <Provider store={store}>
            <div>
              <CssBaseline />
              <App />
            </div>
          </Provider>
        </DemoFrame>
      </MuiThemeProvider>
    )
  }
}

const root = document.getElementById('root')
ReactDOM.render(<Application />, root)
