import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import styles from './styles'
import CurrencyInput from '../CurrencyInput'

const Step2View = ({ classes, inputValue, onChange, updateView }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <CurrencyInput
        onChange={onChange('estimatedFunding')}
        placeholder="How much do you expect to raise?"
        defaultValue={inputValue}
        updateView={updateView}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton
          className={classes.iconButton}
          style={{ marginRight: 5 }}
          onClick={() => updateView(1)}
        >
          <FontAwesomeIcon className={classes.icon} icon={faArrowLeft} />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          onClick={() => updateView(3)}
        >
          <FontAwesomeIcon className={classes.icon} icon={faArrowRight} />
        </IconButton>
      </div>
    </div>
  )
}

export default withStyles(styles)(Step2View)
