const styles = theme => ({
  root: {
    width: '100%',
    height: 350,
    backgroundColor: theme.palette.primary.main,
    border: 'none'
  }
});

export default styles;
