import React from 'react';
import Typography from '@material-ui/core/Typography';

const labels = [
  'Tell us about your event',
  'Pricing',
  "What's the name of your event?",
  "What's your first and last name?",
  "What's your phone number?",
  "What's your email address?",
  'Choose your password',
  'Choose your password'
];

export default function CustomLabel({ step, existingUserFound, ...props }) {
  if (step === 7 && existingUserFound) {
    return <Typography variant="h6">Enter your password</Typography>;
  } else if (step === 8 && existingUserFound) {
    return <Typography variant="h6">Enter your password</Typography>;
  } else {
    return (
      <div>
        <Typography variant="h6">{labels[step - 1]}</Typography>
        {step === 2 && (
          <div>
            <Typography variant="h3">3% or $3,000</Typography>
            <Typography variant="subtitle1">
              {`Total Event.Gives fees : ${props.totalFees}`}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}
