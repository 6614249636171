import React from 'react'
import PropTypes from 'prop-types'
import { create } from 'jss'
import { withStyles, jssPreset } from '@material-ui/styles'
import { StylesProvider } from '@material-ui/styles'
import { MuiThemeProvider } from '@material-ui/core'
import NoSsr from '@material-ui/core/NoSsr'
import rtl from 'jss-rtl'
import Frame from 'react-frame-component'
import theme from '../../themes/main'
import styles from './styles'

class DemoFrame extends React.Component {
  state = {
    ready: false
  }

  handleRef = ref => {
    this.contentDocument = ref ? ref.node.contentDocument : null
    this.contentWindow = ref ? ref.node.contentWindow : null
  }

  onContentDidMount = () => {
    this.setState({
      ready: true,
      jss: create({
        plugins: [...jssPreset().plugins, rtl()],
        insertionPoint: this.contentWindow['jss-insert']
      }),
      sheetsManager: new Map()
      // container: this.contentDocument.body
    })
  }

  onContentDidUpdate = () => {
    this.contentDocument.body.dir = theme.direction
  }

  render() {
    const { children, classes } = this.props

    // NoSsr fixes a strange concurrency issue with iframe and quick React mount/unmount
    return (
      <NoSsr>
        <Frame
          ref={this.handleRef}
          className={classes.root}
          contentDidMount={this.onContentDidMount}
          contentDidUpdate={this.onContentDidUpdate}
          initialContent='<!DOCTYPE html><html><head><link href="https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,700,700i,800,800i" rel="stylesheet"/><link href="https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i" rel="stylesheet"/><link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/><link
      rel="stylesheet" href="https://assets.bidr.co/fonts/aller-display.css"/><style>a {cursor: pointer;color: white !important;text-decoration: underline;}</style></head><body><div class="jss-insert"></div><div class="root"></div></body></html>'
        >
          <div id="jss-insert" />
          {this.state.ready ? (
            <StylesProvider
              jss={this.state.jss}
              sheetsManager={this.state.sheetsManager}
            >
              <MuiThemeProvider theme={theme}>
                {React.cloneElement(children, {
                  // container: this.state.container
                })}
              </MuiThemeProvider>
            </StylesProvider>
          ) : null}
        </Frame>
      </NoSsr>
    )
  }
}

DemoFrame.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(DemoFrame)
