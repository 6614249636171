import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField, IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import styles from './styles'

const Step3View = ({
  classes,
  inputValue,
  checkKeyword,
  checkingKeyword,
  eventTitle,
  onChange,
  updateView,
  setEmptyInput,
  timeoutLoader
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <TextField
        id="event-title"
        autoFocus
        onChange={onChange('eventTitle')}
        className={classes.textField}
        // placeholder="Enter your event name"
        defaultValue={inputValue}
        margin="normal"
        inputProps={{ 'aria-label': 'url' }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            !eventTitle || inputValue !== eventTitle || isNaN(inputValue[0])
              ? checkKeyword()
              : updateView(4)
          }
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton
          className={classes.iconButton}
          style={{ marginRight: 5 }}
          onClick={() => {
            updateView(2)
            setEmptyInput(false)
          }}
        >
          <FontAwesomeIcon className={classes.icon} icon={faArrowLeft} />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          disabled={checkingKeyword || timeoutLoader}
          onClick={() => {
            !eventTitle || inputValue !== eventTitle || isNaN(inputValue[0])
              ? checkKeyword()
              : updateView(4)
          }}
        >
          <FontAwesomeIcon className={classes.icon} icon={faArrowRight} />
        </IconButton>
      </div>
    </div>
  )
}

export default withStyles(styles)(Step3View)
