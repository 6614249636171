import React from 'react'
import MaskedInput from 'react-text-mask'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'

function TextMaskCustom(props) {
  const { inputRef, ...other } = props
  const phonePattern = '([0-9]{3})s[0-9]{3}.[0-9]{4}'
  return (
    <MaskedInput
      pattern={phonePattern}
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      guide={false}
      // placeholderChar={'\u2000'}
      // showMask
    />
  )
}

export default function PhoneInput(props) {
  const {
    inputValue,
    onChange,
    onBlur,
    isValid,
    helperText,
    onKeyDown,
    ...other
  } = props
  return (
    <FormControl fullWidth>
      <Input
        autoFocus
        {...other}
        fullWidth
        value={inputValue}
        onChange={onChange('phone')}
        onBlur={onBlur}
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
        onKeyDown={onKeyDown}
      />
    </FormControl>
  )
}
