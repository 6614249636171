const styles = theme => ({
  inputContainer: {
    maxWidth: 507,
    backgroundColor: theme.palette.primary.dark,
    padding: 12,
    paddingLeft: 18,
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textField: {
    width: '100%',
    maxWidth: 450
  },
  inputLabel: {
    fontFamily: 'Raleway'
  },
  eventGivesFee: {
    fontFamily: 'Aller Display'
  },
  creditCardFee: {
    fontFamily: 'Raleway'
  },
  iconButton: {
    color: theme.palette.primary.light
  },
  icon: {
    fontSize: 22,
    height: 22,
    color: '#FFFFFF'
  },
  inputAdornment: {
    fontSize: 45
  },
  footer: {
    maxWidth: 507,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  inputFeedback: {
    fontStyle: 'italic',
    color: theme.palette.primary.light,
    marginTop: 5
  },
  stepCounter: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: '#FFFFFF'
  }
})

export default styles
