import { createMuiTheme } from '@material-ui/core/styles'

const primary = '#4C2273'
const secondary = '#ff209c'

export default createMuiTheme({
  palette: {
    primary: {
      light: '#DB80FF',
      dark: '#441e69',
      main: primary
    },
    secondary: {
      main: secondary
    },
    text: {
      primary: '#FFFFFF',
      secondary: secondary
    },
    background: {
      default: 'transparent'
    }
  },
  typography: {
    fontFamily: ['Aller', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['Aller Display', 'sans-serif'].join(',')
    },
    h2: {
      fontFamily: ['Raleway', 'sans-serif'].join(',')
    },
    h3: {
      fontFamily: ['Aller Display', 'sans-serif'].join(',')
    },
    h4: {
      fontFamily: ['Raleway', 'sans-serif'].join(',')
    },
    h5: {
      fontFamily: ['Raleway', 'sans-serif'].join(',')
    },
    h6: {
      fontFamily: ['Raleway', 'sans-serif'].join(',')
    },
    subtitle1: {
      fontFamily: ['Raleway', 'sans-serif'].join(','),
      fontSize: 14
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 100
    }
  },
  overrides: {
    MuiInput: {
      root: {
        fontFamily: 'Lato, sans-serif',
        fontSize: '1.5rem',
        color: '#FFFFFF',
        opacity: 0.8
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid transparent'
        },
        '&:after': {
          borderBottom: '2px solid transparent'
        },
        '&:hover:not($disabled):before': {
          borderBottom: '2px solid transparent'
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: '#FFFFFF',
        opacity: 0.3,
        fontSize: '1.5rem',
        '&$focused': {
          color: secondary,
          opacity: 1
        }
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0
      }
    },
    MuiButton: {
      containedSecondary: {
        color: '#FFFFFF'
      }
    },
    MuiLinearProgress: {
      colorSecondary: {
        backgroundColor: primary
      },
      barColorSecondary: {
        backgroundColor: '#32154f'
      }
    }
  }
})
