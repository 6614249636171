import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  IconButton
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styles from './styles';

const options = [
  { label: 'Sell Tickets', value: 'sellTickets', href: '#ticketing-section' },
  { label: 'Check-In', value: 'checkIn', href: '#checkin-section' },
  {
    label: 'Auction/Raffle',
    value: 'auctionOrRaffle',
    href: '#fundraising-section'
  },
  {
    label: 'Text to Donate',
    value: 'textToDonate',
    href: '#fundraising-section'
  },
  { label: 'Sell Items', value: 'sellItems', href: '#fundraising-section' },
  {
    label: 'Mobile Checkout',
    value: 'mobileCheckout',
    href: '#checkout-section'
  }
];

const Step1View = ({ classes, values, onChange, updateView }) => {
  const [hover, setHover] = useState(null);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <FormControl className={classes.radioGroup}>
        <FormGroup className={classes.radioColumn}>
          {options.slice(0, 3).map((option, i) => (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  checked={values[option.value]}
                  onChange={onChange(option.value)}
                  value={option.value}
                />
              }
              label={
                <span
                  className={'eventCheckbox'}
                  href={option.href}
                  onMouseEnter={() => setHover(i)}
                  onMouseLeave={() => setHover(null)}
                  style={{
                    textDecoration: hover === i ? 'underline' : 'none'
                  }}
                >
                  {option.label}
                </span>
              }
            />
          ))}
        </FormGroup>
        <FormGroup className={classes.radioColumn}>
          {options.slice(3).map((option, i) => (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  checked={values[option.value]}
                  onChange={onChange(option.value)}
                  value={option.value}
                />
              }
              label={
                <span
                  className={'eventCheckbox'}
                  href={option.href}
                  onMouseEnter={() => setHover(i + 3)}
                  onMouseLeave={() => setHover(null)}
                  style={{
                    textDecoration: hover === i + 3 ? 'underline' : 'none'
                  }}
                >
                  {option.label}
                </span>
              }
            />
          ))}
        </FormGroup>
      </FormControl>
      <IconButton className={classes.iconButton} onClick={() => updateView(2)}>
        <FontAwesomeIcon className={classes.icon} icon={faArrowRight} />
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(Step1View);
