import React from 'react'
import { isEmpty } from 'ramda'
import { withStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import styles from './styles'
import PhoneInput from '../PhoneInput'

const Step5View = ({
  classes,
  inputValue,
  onChange,
  setEmptyPhoneError,
  checkPhone,
  updateView
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <PhoneInput
          onChange={onChange}
          inputValue={inputValue}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              isEmpty(inputValue) ? setEmptyPhoneError(true) : checkPhone()
            }
          }}
        />
      </div>
      <div className={classes.arrowContainer}>
        <IconButton
          className={classes.iconButton}
          style={{ marginRight: 5 }}
          onClick={() => updateView(4)}
        >
          <FontAwesomeIcon className={classes.icon} icon={faArrowLeft} />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            isEmpty(inputValue) ? setEmptyPhoneError(true) : checkPhone()
          }}
        >
          <FontAwesomeIcon className={classes.icon} icon={faArrowRight} />
        </IconButton>
      </div>
    </div>
  )
}

export default withStyles(styles)(Step5View)
