const arr = [
  'HOPE',
  'TOKIDS',
  'CLASSOF15',
  'WARRIOR',
  'THEATRE',
  'LOVE',
  'MYCAUSE',
  'AMERICA',
  'UNICEF'
];

const stringGenerator = num => {
  return [...Array(num)]
    .map(i => (~~(Math.random() * 36)).toString(36))
    .join('');
};

export default function urlLooper(i, func, bool) {
  const charLength = arr[i].length;
  const lastItem = arr.length - 1;
  if (bool === true) {
    setTimeout(() => {
      func(stringGenerator(charLength));
      setTimeout(() => {
        func(stringGenerator(charLength));
        setTimeout(() => {
          func(arr[i]);
          if (i === lastItem) {
            return urlLooper(0, func, bool);
          } else {
            return urlLooper(i + 1, func, bool);
          }
        }, 100);
      }, 100);
    }, 5000);
  }
}
