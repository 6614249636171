const initState = {
  checkingTitleAvailability: false,
  titleIsAvailable: false,
  eventTitle: null
}

const eventReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_TITLE_IS_AVAILABLE':
      return {
        ...state,
        titleIsAvailable: action.bool
      }
    case 'CHECKING_TITLE_AVAILABILITY':
      return {
        ...state,
        checkingTitleAvailability: action.bool
      }
    default:
      return state
  }
}

export default eventReducer
