const styles = theme => ({
  icon: {
    marginLeft: 7,
    marginBottom: 3,
    fontSize: 22,
    height: 22,
    color: theme.palette.primary.light
  }
});

export default styles;
