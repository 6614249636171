import React from 'react'
import NumberFormat from 'react-number-format'
import { withStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import styles from './styles'

function CustomNumberFormat(props) {
  const { inputRef, onChange, onKeyDown, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        })
      }}
      onKeyDown={onKeyDown}
      thousandSeparator
      prefix="$"
    />
  )
}

const CurrencyInput = ({
  classes,
  onChange,
  placeholder,
  defaultValue,
  updateView
}) => {
  return (
    <TextField
      id="currency-input"
      autoFocus
      onChange={onChange}
      className={classes.textField}
      placeholder={placeholder}
      defaultValue={defaultValue}
      InputProps={{
        inputComponent: CustomNumberFormat
      }}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          updateView(3)
        }
      }}
    />
  )
}

export default withStyles(styles)(CurrencyInput)
