import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { isNil, isEmpty, filter } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import styles from './styles';
import calculateFees from '../../lib/calculateFees';
import alphanumeric from '../../lib/alphanumeric';
import urlLooper from '../../lib/urlLooper';
import LinearDeterminate from '../LinearDeterminate';
import CustomLabel from '../CustomLabel';
import Step1View from '../Step1View';
import Step2View from '../Step2View';
import Step3View from '../Step3View';
import Step4View from '../Step4View';
import Step5View from '../Step5View';
import Step6View from '../Step6View';
import Step7View from '../Step7View';
import MagicLinkView from '../MagicLinkView';
import {
  _authByPass,
  _authByPassEmail,
  _checkEventTitle,
  _checkPhoneNumber,
  _checkEmail,
  _createNewUser,
  _createNewEvent,
  _updateNewEvent,
  _generateMagicLink
} from '../../store/actions/eventActions';

function App({ classes }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [currentUrl, setCurrentUrl] = useState('classof15');
  const [totalFees, setTotalFees] = useState(0);
  const [checkingKeyword, setCheckingKeyword] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);
  const [emailExists, setEmailExists] = useState(null);
  const [eventTitle, setEventTitle] = useState(null);
  const [emptyKeywordError, setEmptyKeywordError] = useState(false);
  const [checkingPhone, setCheckingPhone] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [existingUserFound, setExistingUserFound] = useState(null);
  const [emptyPhoneError, setEmptyPhoneError] = useState(false);
  const [timeoutLoader, setTimeoutLoader] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [magicLink, setMagicLink] = useState(null);
  const [badPassword, setBadPassword] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState({
    sellTickets: false,
    checkIn: false,
    auctionOrRaffle: false,
    textToDonate: false,
    sellItems: false,
    mobileCheckout: false
  });
  const [inputValues, setInputValues] = useState({
    eventTitle: '',
    estimatedFunding: '',
    name: '',
    phone: '',
    email: '',
    existing_email: '',
    password: '',
    user_url: '',
    user_url_hash: '',
    user_id: ''
  });
  const [emptyInput, setEmptyInput] = useState(false);

  useEffect(() => urlLooper(0, setCurrentUrl, isNil(eventTitle)), [eventTitle]);

  function magicLinkRedirect() {
    window.location.assign(magicLink);
  }

  const updateView = value => {
    setCurrentStep(value);
  };
  const handleCheckboxValues = name => event => {
    setCheckboxValues({ ...checkboxValues, [name]: event.target.checked });
  };
  const handleInputValues = name => event => {
    if (name === 'estimatedFunding') {
      calculateFees(parseFloat(event.target.value), setTotalFees);
      setInputValues({
        ...inputValues,
        estimatedFunding: event.target.value
      });
    } else {
      setInputValues({ ...inputValues, [name]: event.target.value });
    }
  };
  // CHECK KEYWORD
  const checkKeyword = () => {
    const keyword = inputValues.eventTitle.replace(/\s/g, '');
    setEmptyKeywordError(false);
    if (alphanumeric(keyword)) {
      setCheckingKeyword(true);
      _checkEventTitle(keyword)
        .then(res => {
          if (res.data.msg === 'keyword available') {
            setIsAvailable(true);
            setTimeoutLoader(true);
            setCheckingKeyword(false);
            setTimeout(() => {
              setEventTitle(keyword);
              setTimeoutLoader(false);
              updateView(4);
              setIsAvailable(null);
            }, 2000);
            console.log(res.data.msg);
          } else {
            setCheckingKeyword(false);
            setIsAvailable(false);
            console.log('Oops! That event title is already taken.');
          }
        })
        .catch(err => {
          setCheckingKeyword(false);
          setIsAvailable(false);
          console.log(err);
        });
    } else if (isEmpty(keyword)) {
      setEmptyKeywordError(true);
    } else {
      console.log('Error: event title can only contain letters and numbers');
    }
  };

  // CHECK PHONE
  const checkPhone = () => {
    const phoneNumber = inputValues.phone.replace(/[- )(]/g, ''); // removes (), - and spaces
    setInputValues({ ...inputValues, phone: phoneNumber });
    setEmptyPhoneError(false);
    setCheckingPhone(true);
    _checkPhoneNumber(phoneNumber)
      .then(res => {
        if (res.data.msg !== 'no user') {
          setExistingUserFound(true);
          setTimeoutLoader(true);
          setCheckingPhone(false);
          setTimeout(() => {
            updateView(7);
            setInputValues({
              ...inputValues,
              user_url: res.data.user_url,
              user_url_hash: res.data.user_url_hash
            });
            setTimeoutLoader(false);
          }, 2000);
        } else {
          setExistingUserFound(false);
          setTimeoutLoader(true);
          setCheckingPhone(false);
          setTimeout(() => {
            setTimeoutLoader(false);
            updateView(6);
          }, 2000);
        }
      })
      .catch(err => {
        setCheckingPhone(false);
        console.log(err);
      });
  };

  // CHECK EMAIL
  const checkEmail = () => {
    const email = inputValues.email;
    setEmailExists(false);
    setEmptyInput(false);
    setCheckingEmail(true);
    _checkEmail(email)
      .then(res => {
        if (res.data.msg !== 'no user') {
          setEmailExists(true);
          setCheckingEmail(false);
          setInputValues({
            ...inputValues,
            user_url: res.data.user_url,
            user_url_hash: res.data.user_url_hash
          });
        } else {
          updateView(7);
          setCheckingEmail(false);
        }
      })
      .catch(err => {
        setCheckingEmail(false);
        console.log(err);
      });
  };

  // CREATE USER --> CREATE EVENT
  const createUserAndEvent = () => {
    _createNewUser(
      inputValues.email,
      inputValues.name,
      inputValues.phone,
      inputValues.password
    )
      .then(res => {
        if (res.data.msg === 'user created') {
          console.log('USER CREATED', res);
          createEvent(
            res.data.user_id,
            res.data.user_url,
            res.data.user_url_hash
          );
        } else {
          setSubmitting(false);
          console.log('CREATE USER ERROR');
        }
      })
      .catch(err => console.log('ERROR', err));
  };

  // CREATE EVENT
  const createEvent = (userId, user_url, user_url_hash) => {
    const userUrl = user_url ? user_url : inputValues.user_url;
    const userUrlHash = user_url_hash
      ? user_url_hash
      : inputValues.user_url_hash;
    const keyword = inputValues.eventTitle;
    const checkboxArray = Object.keys(filter(n => n === true, checkboxValues));
    const estimatedFunding = inputValues.estimatedFunding;
    // EXECUTE
    _createNewEvent(userId, keyword)
      .then(res => {
        if (res.data.msg === 'event created') {
          console.log('EVENT CREATED');
          _updateNewEvent(
            res.data.eid,
            keyword,
            checkboxArray,
            estimatedFunding
          ).then(res2 => {
            if (res2.data.msg === 'attributes updated') {
              updateView(8);
              console.log('EVENT UPDATED');
              setTimeoutLoader(true);
              setTimeout(() => {
                _generateMagicLink(userUrl, userUrlHash, res.data.eid)
                  .then(res => {
                    setTimeoutLoader(false);
                    setSubmitting(false);
                    setMagicLink(res);
                    updateView(9);
                  })
                  .catch(err => console.log(err));
                setTimeoutLoader(false);
                setSubmitting(false);
              }, 2000);
            } else {
              setSubmitting(false);
              updateView(8);
              console.log('EVENT UPDATE ERROR');
            }
          });
        } else {
          setSubmitting(false);
          updateView(8);
          console.log('CREATE EVENT ERROR');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  // AUTHENTICATE EXISTING USER
  const authenticateUser = () => {
    console.log('PHONE:', inputValues.phone);
    if (emailExists) {
      console.log('HERE');
      return _authByPassEmail(inputValues.email, inputValues.password)
        .then(res => {
          const userId = res.data.user[0].user_id;
          if (res.data.msg === 'pass') {
            setTimeoutLoader(true);
            updateView(8);

            createEvent(userId);
          } else {
            setSubmitting(false);
            console.log('ERROR');
          }
        })
        .catch(err => console.log(err));
    } else {
      _authByPass(inputValues.phone, inputValues.password)
        .then(res => {
          if (res.data.msg === 'pass') {
            const userId = res.data.user[0].user_id;
            setTimeoutLoader(true);
            updateView(8);

            createEvent(userId);
          } else {
            setBadPassword(true)
            setSubmitting(false);
            console.log('ERROR');
          }
        })
        .catch(err => console.log(err));
    }
  };

  // FINAL SUBMIT
  const submitValues = () => {
    setSubmitting(true);
    if (existingUserFound) {
      console.log('Existing user!');
      authenticateUser();
    } else if (isEmpty(inputValues.user_id)) {
      createUserAndEvent();
    } else {
      createEvent(inputValues.user_url, inputValues.user_url_hash);
    }
  };

  const loginDirect = () => {
    setExistingUserFound(true);
    updateView(7);
  };

  return (
    <div className={classes.pageContainer}>
      {/* HEADER */}
      <Typography variant="h1" color="secondary" className={classes.url}>
        {isNil(eventTitle)
          ? `/${currentUrl.toUpperCase()}`
          : `/${eventTitle.toUpperCase()}`}
      </Typography>
      {currentStep !== 9 ? (
        <div>
          {/* LABEL */}
          <CustomLabel
            step={currentStep}
            existingUserFound={existingUserFound}
            totalFees={`$${totalFees.toFixed(2)}`}
          />
          <div className={classes.rowAlignCenter}>
            {/* STEP CONTENT */}
            {
              <div className={classes.inputContainer}>
                {currentStep === 1 && (
                  <Step1View
                    values={checkboxValues}
                    currentStep={currentStep}
                    onChange={handleCheckboxValues}
                    updateView={updateView}
                  />
                )}
                {currentStep === 2 && (
                  <Step2View
                    inputValue={inputValues.estimatedFunding}
                    onChange={handleInputValues}
                    updateView={updateView}
                  />
                )}
                {currentStep === 3 && (
                  <Step3View
                    checkKeyword={checkKeyword}
                    checkingKeyword={checkingKeyword}
                    eventTitle={eventTitle}
                    timeoutLoader={timeoutLoader}
                    inputValue={inputValues.eventTitle}
                    onChange={handleInputValues}
                    updateView={updateView}
                    setEmptyInput={setEmptyInput}
                  />
                )}
                {currentStep === 4 && (
                  <Step4View
                    inputValue={inputValues.name}
                    onChange={handleInputValues}
                    updateView={updateView}
                    setEmptyInput={setEmptyInput}
                  />
                )}
                {currentStep === 5 && (
                  <Step5View
                    inputValue={inputValues.phone}
                    onChange={handleInputValues}
                    setEmptyPhoneError={setEmptyPhoneError}
                    checkPhone={checkPhone}
                    updateView={updateView}
                  />
                )}
                {currentStep === 6 && !existingUserFound && (
                  <Step6View
                    inputValue={inputValues.email}
                    onChange={handleInputValues}
                    updateView={updateView}
                    checkEmail={checkEmail}
                    setEmptyInput={setEmptyInput}
                  />
                )}
                {currentStep === 7 || currentStep === 8 ? (
                  <Step7View
                    inputValue={inputValues.password}
                    onChange={handleInputValues}
                    updateView={updateView}
                    submitValues={submitValues}
                    setEmptyInput={setEmptyInput}
                  />
                ) : (
                  <div />
                )}
              </div>
            }
            {/* SPINNER */}
            {checkingKeyword ||
            checkingPhone ||
            checkingEmail ||
            submitting ||
            timeoutLoader ? (
              <CircularProgress color="secondary" style={{ marginLeft: 10 }} />
            ) : (
              <div />
            )}
          </div>
          {/* PROGRESS BAR */}
          <LinearDeterminate step={currentStep} />
          <div className={classes.footer}>
            {/* TOTAL FEES */}
            {currentStep === 2 && (
              <Typography variant="subtitle1" className={classes.inputFeedback}>
                plus credit card processing
                {/* {`Total Bidr fees: $${totalFees.toFixed(2)}`} */}
              </Typography>
            )}
            {/* KEYWORD CHECK FEEDBACK */}
            {currentStep === 3 && (
              <Typography variant="subtitle1" className={classes.inputFeedback}>
                {checkingKeyword
                  ? 'Checking keyword…'
                  : isAvailable
                  ? 'Great! That keyword is available.'
                  : !isAvailable && !isNil(isAvailable) // isAvailable === false
                  ? 'Sorry, that keyword is already being used.'
                  : emptyKeywordError
                  ? "Don't by shy, type something."
                  : ''}
              </Typography>
            )}
            {/* PHONE CHECK FEEDBACK */}
            {currentStep === 5 && (
              <Typography variant="subtitle1" className={classes.inputFeedback}>
                {checkingPhone ? (
                  'Checking number...'
                ) : existingUserFound ? (
                  'Hey we know that number, welcome back!'
                ) : existingUserFound === false ? (
                  <div>
                    Hey, we haven't met yet? Nice to meet you.
                    <br />
                    Let's create an account for you.
                  </div>
                ) : emptyPhoneError ? (
                  'Please enter a phone number.'
                ) : (
                  ''
                )}
              </Typography>
            )}
            {currentStep === 6 && (
              <Typography variant="subtitle1" className={classes.inputFeedback}>
                {emailExists ? (
                  <div>
                    An account already exists for this email address. Please{' '}
                    <span
                      style={{
                        cursor: 'pointer',
                        color: 'white',
                        textDecoration: 'underline'
                      }}
                      onClick={() => loginDirect()}
                    >
                      sign in
                    </span>{' '}
                    to your existing account or provide a new email address.
                  </div>
                ) : (
                  ''
                )}
              </Typography>
            )}
            {currentStep === 7 && (
              <Typography variant="subtitle1" className={classes.inputFeedback}>
                {badPassword ? (
                  <div>
                    Incorrect password, please try again or{' '}
                    <a href="https://manager.event.gives" target="_top">try logging in here</a>
                  </div>
                ) : (
                  ''
                )}
              </Typography>
            )}
            {currentStep !== 2 && currentStep !== 3 && (
              <Typography variant="subtitle1" className={classes.inputFeedback}>
                {emptyInput ? "Don't be shy, type something." : ''}
              </Typography>
            )}
            {/* STEP COUNTER */}
            <Typography variant="subtitle1" className={classes.stepCounter}>
              {`${currentStep < 8 ? currentStep : 7} / 7`}
            </Typography>
          </div>
        </div>
      ) : (
        // MAGIC LINK VIEW
        <MagicLinkView redirect={magicLinkRedirect} />
      )}
    </div>
  );
}

export default compose(withStyles(styles), withWidth())(App);
