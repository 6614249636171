import React from 'react';
import { isEmpty } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import { TextField, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import styles from './styles';

const Step6View = ({
  classes,
  inputValue,
  onChange,
  setEmptyInput,
  checkEmail,
  updateView
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <TextField
          id="email"
          autoFocus
          onChange={onChange('email')}
          className={classes.textField}
          defaultValue={inputValue}
          margin="normal"
          inputProps={{ 'aria-label': 'email' }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              isEmpty(inputValue) ? setEmptyInput(true) : checkEmail();
            }
          }}
        />
      </div>
      <div className={classes.arrowContainer}>
        <IconButton
          className={classes.iconButton}
          style={{ marginRight: 5 }}
          onClick={() => {
            setEmptyInput(false);
            updateView(5);
          }}
        >
          <FontAwesomeIcon className={classes.icon} icon={faArrowLeft} />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          onClick={() =>
            isEmpty(inputValue) ? setEmptyInput(true) : checkEmail()
          }
        >
          <FontAwesomeIcon className={classes.icon} icon={faArrowRight} />
        </IconButton>
      </div>
    </div>
  );
};

export default withStyles(styles)(Step6View);
