const styles = theme => ({
  textField: {
    backgroundColor: 'transparent',
    width: '100%',
    maxWidth: 450
  },
  firstLastName: {
    display: 'flex'
  },
  arrowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start'
  },
  iconButton: {
    color: theme.palette.primary.light
  },
  icon: {
    fontSize: 22,
    height: 22,
    color: '#FFFFFF'
  }
})

export default styles
