import React from 'react'
import { isEmpty } from 'ramda'
import { withStyles } from '@material-ui/core/styles'
import { TextField, IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import styles from './styles'

const Step4View = ({
  classes,
  inputValue,
  onChange,
  setEmptyInput,
  updateView
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <TextField
          id="last-name"
          autoFocus
          // placeholder="Name"
          onChange={onChange('name')}
          className={classes.textField}
          defaultValue={inputValue}
          margin="normal"
          inputProps={{ 'aria-label': 'name' }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              isEmpty(inputValue) ? setEmptyInput(true) : updateView(5)
            }
          }}
        />
      </div>
      <div className={classes.arrowContainer}>
        <IconButton
          className={classes.iconButton}
          style={{ marginRight: 5 }}
          onClick={() => {
            setEmptyInput(false)
            updateView(3)
          }}
        >
          <FontAwesomeIcon className={classes.icon} icon={faArrowLeft} />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          onClick={() =>
            isEmpty(inputValue) ? setEmptyInput(true) : updateView(5)
          }
        >
          <FontAwesomeIcon className={classes.icon} icon={faArrowRight} />
        </IconButton>
      </div>
    </div>
  )
}

export default withStyles(styles)(Step4View)
